@import "config";

.appsumoInfo {
  display: flex;
  align-items: center;
  padding: $contentDefaultIndent $contentDefaultIndent * 2;
  background-color: lighten($uiWarningColor, 15%);
  color: $uiBlackColor;
  > svg {
    font-size: 1.2em;
  }
  > span {
    padding-right: 1em;
    overflow: hidden;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  > button {
    margin-left: auto;
    white-space: nowrap;
  }
}

.addBusinessButtonContainer {
  margin: auto;
  text-align: center;
}

.wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;
  .scrollContainer {
    width: 100%;
  }
}

[data-desktop] {
  .scrollContainer {
    display: flex;
    position: relative;
    flex: auto;
    width: 100%;
    overflow: auto;
  }
}
