@import "nlib/config";

.selectRpaBlock {
  .header {
    margin-bottom: $contentDefaultIndent * 2;
    font-size: 1.25rem;
    font-weight: 500;
  }
  .blocks {
    display: flex;
  }
  .block {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 50%;
    padding: $contentDefaultIndent * 2;
    transition: transform 0.2s, box-shadow 0.2s;
    border: 1px solid $uiBorderColor;
    border-radius: $uiBorderRadius;
    background-color: $uiWhiteColor;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0);
    cursor: pointer;
    + .block {
      margin-left: $contentDefaultIndent * 3;
    }
    &:not(.selected):hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    &.selected {
      border-color: $uiPrimaryColor;
    }
    .title {
      margin-bottom: $contentDefaultIndent * 3;
      font-size: 1rem;
      font-weight: 500;
    }
    .price {
      display: flex;
      align-items: flex-end;
      margin-bottom: $contentDefaultIndent * 2;
      padding-bottom: $contentDefaultIndent * 2;
      border-bottom: 1px solid $uiBorderColor;
      line-height: inherit;
      b {
        margin-right: 0.2em;
        font-size: 1.5rem;
      }
      span {
        line-height: 1;
      }
    }
    .items {
      margin-bottom: auto;
      .item {
        display: flex;
        align-items: flex-start;
        margin-top: $contentDefaultIndent * 1.5;
        img,
        svg {
          width: 16px;
          height: 16px;
          margin-top: 1px;
          margin-right: $contentDefaultIndent;
        }
      }
    }
    .warning {
      margin-top: 1rem;
      padding: $contentDefaultIndent;
      border-radius: $uiBorderRadius;
      background-color: rgba($uiWarningColor, 0.1);
      font-size: 0.75rem;
      text-align: center;
      svg {
        color: $uiWarningColor;
        font-size: 1rem;
      }
    }
    .integration {
      display: flex;
      align-items: center;
      margin-top: $contentDefaultIndent * 2;
      padding-top: $contentDefaultIndent * 2;
      border-top: 1px solid $uiBorderColor;
      .text {
        margin-right: auto;
      }
      img {
        width: 2rem;
        height: 2rem;
        margin-left: $contentDefaultIndent;
      }
    }
  }
}
